import React from 'react';
import {makeStyles} from "@mui/styles";
import Header from "./Header";
import Success from "./Carriere";
import Overview from "./Overview";
import Carriere from "./Carriere";
import Partenaire from "../Pages/Partenaire";
import Biographie from "../Pages/Biographie";
import ImageBiographie from "./Biographie/ImageBiographie";
import Course from "../Pages/Course";
import Animation from "./Animation";
import QuiltedImageList from "./standard/QuiltedImageList";
import NextRaceComponent from "./Course/NextRaceComponent";
import Box from "@mui/material/Box";
import {IconButton} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import {Link as Scroll} from "react-scroll";

const useStyles=makeStyles((theme) => ({
    goDown:{
        color: "#070707",
        alignItems:"center",

    },
    video:{

        maxWidth:"100%",
        Width:"auto",
        position:"relative",
        backgroundColor:"#070707",



    }
}));




 function Appbar() {



     const classes =useStyles();

  return (<>

          <div >


                <Header/>

<Box >
              <Box  sx={{ marginTop: {xs:10, md:2} }} id="header">
                  <Scroll to="victories" smooth={true}>
                      <IconButton>

                  <video  autoPlay muted loop className={classes.video}>

                  <source src={process.env.PUBLIC_URL + '/images/amelie_site.mp4'} type="video/mp4"  />



                      </video>
                      </IconButton>
                  </Scroll>
              </Box>
</Box>
              <Overview/>





          </div>
      </>


)

}

export default Appbar;
