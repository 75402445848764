import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Appbar from "./components/Appbar";
import Biographie from "./Pages/Biographie";

import Contact from "./Pages/Contact";
import Carriere from "./components/Carriere";
import Partenaire from "./Pages/Partenaire";
import Course from "./Pages/Course";
import Test from "./Pages/Test";
import Header from "./components/Header";
import BottomMenu from "./components/standard/BottomMenu";
import {CssBaseline} from "@mui/material";
import NextRaceComponent from "./components/Course/NextRaceComponent";
import ScrollToTop from "./helpers/ScrollToTop";
import Serial from "./components/Serial";
import Impressum from "./Pages/Impressum";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <CssBaseline/>
      <BrowserRouter>
          <ScrollToTop/>
          <Routes>
              <Route path="/" element={<Appbar />} />
              <Route path="home" element={<Appbar/>}/>
              <Route path="contact" element={<Contact />} />
              <Route path="biographie" element={<Biographie />} />
              <Route path="carriere" element={<Carriere/>}/>
              <Route path="partenaires" element={<Partenaire/>}/>
              <Route path="course" element={<Course/>}/>
              <Route path="impressum" element={<Impressum/>}/>
              <Route path="test" element={<Serial/>}/>
          </Routes>
      </BrowserRouter>

  <BottomMenu/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
