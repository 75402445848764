import React, {useEffect, useState} from 'react';
import {Collapse, IconButton} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";
import { Link as Scroll} from 'react-scroll';


const useStyles=makeStyles((theme) => ({

    root:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        height:'150vh',
        fontFamily:'Nunito',
        backgroundImage: `url(${process.env.PUBLIC_URL + '/images/amelie.jpg'})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize:'cover',

    },
    appbar:{
        background:'none',
    },
    titleColor:{
        color:"#fff",
    },

    icon: {
        color:'#fff',
        fontSize:"2rem"
    },
    appbarTitle:{
        flexGrow:'1',

    },
    appbarWrapper:{

        width:'80%',
        margin:'0 auto'

    },
    title:{
        color:"#070707",
        fontFamily:"Nunito",
        fontSize:"4rem",
    },
    container:{
        textAlign: 'center',
    },
    goDown:{
        color: "#fff",

    }
}));



function Animation() {


    const classes =useStyles();
    const [checked, setChecked] = useState(false);


    useEffect(() => {
        setChecked(true);
    }, []);




    return (

        <div className={classes.root}>
            <Collapse
                in={checked}
                {...(checked ? { timeout: 1000 } : {})}
                collapsedHeight={100}
            >
                <div className={classes.container} id="header">
                    <h1 className={classes.title}>Bienvenue sur <br/> <span className={classes.titleColor}>mon site</span></h1>
                    <Scroll to="victories" smooth={true}>
                        <IconButton>
                            <ExpandMore sx={{fontSize:"10rem"}} className={classes.goDown}/>
                        </IconButton>
                    </Scroll>


                </div>
            </Collapse>
        </div>
            )
}

export default Animation;
