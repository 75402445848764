import {makeStyles} from "@mui/styles";
import {AppBar, Box, Button, Collapse, CssBaseline, IconButton, Toolbar} from "@mui/material";
import MenuOutlindedIcon from '@mui/icons-material/MenuOutlined';
import {ExpandMore, MenuOutlined} from "@mui/icons-material";
import React from "react";
import {useState,useEffect} from "react";
import Animation from "./Animation";
import { Link as Scroll} from 'react-scroll';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'
import {Link, NavLink, Redirect} from "react-router-dom";
import Typography from "@mui/material/Typography";
import ButtonBases from "./standard/ButtonBases";
import About from "../static/About";






const pages = ['Home', 'Biographie','Carriere','Partenaires','Course','Contact'];
const useStyles=makeStyles((theme) => ({

    root:{
        display:'flex',
    alignItems:'center',
        justifyContent:'center',

        fontFamily:'Nunito',
        backgroundImage: `url(${process.env.PUBLIC_URL + '/images/background/melchsee.jpg'})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize:'cover',


    },
    appbar:{
        background:'none',
    },
    titleColor:{
        color:"#fff",
    },

    appbarTitle:{

        color:'#070707',


    },
    appbarWrapper:{

      width:'100%',
        margin:'0 auto'

    },
    title:{
        color:"#070707",
        fontFamily:"Nunito",
        fontSize:"4rem",
    },
    container:{
textAlign: 'center',
    },
    goDown:{
        color: "#fff",
        fontSize:"6rem",
    },
    logo:{
       marginLeft:50,

    },

    link:{
        textDecoration:"none",
    },


}));




export default function Header (){

    const classes =useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);

    };



    return (
        <div className={classes.root} >
            <CssBaseline/>
            <AppBar className={classes.appbar} elevation={0} sx={
                {background:"#5abcf8"}
            }>


                <Toolbar className={classes.appbarWrapper}>
                    <IconButton>
                        <MenuOutlindedIcon
                            sx={{fontSize:"1.5rem",color:'#fff'}}
                            className={classes.icon}
                                           id="basic-button"
                                           aria-controls={open ? 'basic-menu' : undefined}
                                           aria-haspopup="true"
                                           aria-expanded={open ? 'true' : undefined}
                                           onClick={handleClick}/>
                    </IconButton>
<Box sx={{display: { xs: 'none', md: 'flex' }}}>
                <h1 className={classes.appbarTitle}>
                    Amélie <span className={classes.titleColor}>Wenger-Reymond</span>
                </h1>
</Box>

                    <img src="/images/logo.png" alt="logo" className={classes.logo} />
                </Toolbar>

            </AppBar>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {pages.map((page) => (
                    <Link  to={"/"+page} className={classes.link} >
                        <Button sx={{ flexDirection: 'row',
                            display:'inline-flex',
                            flexWrap: 'wrap'}}
                                >
                        <MenuItem  key={page} onClick={handleClose}>
                            {page}
                        </MenuItem>
                        </Button>
                    </Link>
                ))}
            </Menu>
            <AppBar sx={{background:"none", marginTop:22.5,alignItems:'center',
                justifyContent:'center',
            }}>


            </AppBar>


        </div>
    )

}
