import React from 'react';
import {makeStyles} from "@mui/styles";
import overviews from "../static/overviews";
import useWindowPosition from "../hook/useWindowPosition";
import ImageOverview from "./ImageOverview";
import QuiltedImageList from "./standard/QuiltedImageList";
import {IconButton} from "@mui/material";
import {ExpandMore, ArrowForwardIos} from "@mui/icons-material";
import {Link as Scroll} from "react-scroll";
import NextRaceComponent from "./Course/NextRaceComponent";
import Stepper from "./standard/Stepper";
import {Link} from "react-router-dom";



const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        justifyItems:'center',
        alignItems:'center',
        flexDirection: 'row',
        display:'inline-flex',
        flexWrap: 'wrap'},

    div: {
        backgroundImage: `url(${process.env.PUBLIC_URL + '/images/background/thyonbackground.jpg'})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',


    },

    goDown:{
            color: "#fff",
            fontSize:"6rem",
        textAlign: 'center',
        justifyItems:'center',
        display:"inline-flex",
        },




}));


export default function () {
    const classes = useStyles();
    const checked= useWindowPosition("header");


    return (
        <div className={classes.div} >


        <div className={classes.root} id="victories" >
            {overviews.map(overview => (
                <ImageOverview  overview={overview} checked={checked}/>
            ))}

        </div>

            <Scroll to="images" smooth={true} >
                <IconButton >
                    <ExpandMore sx={{fontSize:"10rem", left:'50%', alignItems:"center"}} className={classes.goDown}/>
                </IconButton>
            </Scroll>


<div id="images" >
            <QuiltedImageList ></QuiltedImageList>

    <Link to={"../Biographie"}>
        <IconButton  >
            <ArrowForwardIos sx={{fontSize:"6rem", left:'50%', alignItems:"center",color: "#fff",
            }} />
        </IconButton>
    </Link>

</div>
        </div>
    );
}

