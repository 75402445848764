import React from 'react';
import {makeStyles} from "@mui/styles";
import {Link} from "react-router-dom";
import partenaireprincipals from "../../static/Partenaire/partenaireprincipal";
import useWindowPosition from "../../hook/useWindowPosition";
import ImagePartenairePrincipal from "./ImagePartenairePrincipal";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',

        justifyItems: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        display: 'inline-flex',
        flexWrap: 'wrap',


    },

}));
export default function () {
    const classes = useStyles();


    return (
        <div className={classes.root} id="victories">
            {partenaireprincipals.map(overview => (
                <ImagePartenairePrincipal overview={overview}/>
            ))}


        </div>
    );
}

