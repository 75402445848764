import React from 'react';
import CourseComponent from "../components/Course/CourseComponent";
import Header from "../components/Header";
import Typography from "@mui/material/Typography";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    div: {
        backgroundImage: `url(${process.env.PUBLIC_URL + '/images/background/Montblanc.jpg'})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize:'cover',




    },

}));

function Course() {
    const classes =useStyles();

    return (<div className={classes.div}>
<Header/>
            <h1 >
                <Typography sx={{color:"#ffffff",
                    fontFamily:"Nunito",
                    fontWeight:'bold',
                    fontSize:"2.5rem",
                    marginTop:25,
                    marginLeft:2,
                    marginBottom:5,
                }}>Les courses de la saison</Typography>
            </h1>

<CourseComponent />

        </div>
    );
}

export default Course;
