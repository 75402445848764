import React from 'react';
import {makeStyles} from "@mui/styles";
import ImageCourse from "./ImageCourse";

import courses from "../../static/Courses/courses";
import ImageNextRace from "./ImageNextRace";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',

        justifyItems:'center',
        alignItems:'center',
        flexDirection: 'row',
        display:'inline-flex',
        flexWrap: 'wrap',



    },

}));
export default function () {
    const classes = useStyles();
    const dates = courses.map(overview => (
        overview.date
    ))

    const now = new Date();

    let closest = Infinity;

    dates.forEach(function(d) {
        let c = d.split("-");

        let check = new Date(c[2], parseInt(c[1])-1, c[0]);


        if (check >= now && (check < new Date(closest) || check < closest)) {
            closest = d;
            console.log(closest)
        }
    });

    let res= courses.includes(closest);
console.log(res)
    return (
        <div className={classes.root}  >

                <ImageNextRace overview={res} />



        </div>
    );
}

