import React, {useState} from 'react';
import {makeStyles} from "@mui/styles";
import ImageCard from "./ImageCard";
import victories from "../static/victories";
import Header from "./Header";
import Typography from "@mui/material/Typography";
import {ArrowForwardIos} from "@mui/icons-material";
import {Collapse, IconButton} from "@mui/material";
import {Link} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',

        justifyItems:'center',
        alignItems:'center',
            flexDirection: 'row',
        display:'inline-flex',
        flexWrap: 'wrap'},

        div: {
            backgroundImage: `url(${process.env.PUBLIC_URL + '/images/background/Montblanc.jpg'})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize:'cover',



        },

}));
export default function Carriere () {
    const classes = useStyles();
   const [checked, setChecked] = useState(true);

        const handleChange = () => {
            setChecked((prev) => !prev);
        };


    return (
        <div className={classes.div}>
<Header/>
            <h1 >
                <Typography sx={{color:"#ffffff",
                    fontFamily:"Nunito",
                    fontSize:"2.5rem",
                    fontWeight:'bold',
                    marginTop:25,
                    marginLeft:2,
                }}>Les éléments clés de ma carrière</Typography>
            </h1>
        <div className={classes.root}  id="victories">
            <h1></h1>
            {victories.map(victorie => (
                <Collapse in={checked}
                    {...(checked ? { timeout: 1000 } : {})}
                          collapsedHeight={400}>
                <ImageCard success={victorie} />
                </Collapse>
            ))}
            <Link to={"../Partenaires"}>
                <IconButton  >
                    <ArrowForwardIos sx={{fontSize:"6rem", left:'50%', alignItems:"center",color: "#fff",
                    }} />
                </IconButton>
            </Link>

        </div>
        </div>
    );
}

