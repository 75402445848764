import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {Collapse} from "@mui/material";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import AlertDialogSlide from "../standard/AlertDialogSlide";
import {useState} from "react";
import Appbar from "../Appbar";


export default function ImageCourse({overview},{setOpen}) {
    let [AlertDialog,setAlertDialog]=useState(false);
    let dateTo = new Date();
    let c = overview.date.split("-");
    let check = new Date(c[2], parseInt(c[1])-1, c[0]);


    return (
        <>
            {check>dateTo ?
            <Card sx={{
                maxHeight: 450 ,
                width:300,
            marginLeft:2,
            marginTop:5}} >

                <CardContent sx={{background:'rgba(0,0,0,0.5)'}}>


                    <Typography  gutterBottom variant="h5" component="div"
                                 sx={{fontFamily:"Nunito",
                                     fontWeight:'bold',
                                     fontSize:'2rem',
                                     color:'#fff',

                                 }}

                    >
                        {overview.title}
                    </Typography>

                    <Typography variant="body2" color="text.secondary"
                                sx={{fontFamily:"Nunito",
                                    fontSize:'1.5rem',
                                    color:'#ddd'}}>
                        {overview.date}












                    </Typography>
                    <Typography variant="body2" color="text.secondary"
                                sx={{fontFamily:"Nunito",
                                    fontWeight:'bold',
                                    fontSize:'1.5rem',
                                    color:'#ffffff'}}>
                        {overview.place}
                    </Typography>
                    <Typography  gutterBottom variant="h5" component="div"
                                 sx={{fontFamily:"Nunito",
                                     fontWeight:'bold',
                                     fontSize:'1.4rem',
                                     color:'#fff',

                                 }}

                    >
                        {overview.nation}
                    </Typography>


                </CardContent>

            </Card> :null}
        </>
    );
}
