import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {Button} from "@mui/material";
import {Link} from "react-router-dom";
import Header from "../Header";
import {makeStyles} from "@mui/styles";




export default function ImageBiographie({overview}) {


    return (
        <>
            <Header/>

            <Card sx={{
                maxWidth: '100%',
                marginTop:20,
                marginLeft:2,
                display:'inline-flex',
                alignItems:'center',
                justifyContent:'center',
                flexWrap: 'wrap'}}
            >
                <CardMedia

                    component="img"
                    with="400"
                    image={overview.url}
                    alt="image"
                />
                <CardContent sx={{background:'rgba(0,0,0,0.5)'}}>
                    <Typography  gutterBottom variant="h5" component="div"
                                 sx={{fontFamily:"Nunito",
                                     fontWeight:'bold',
                                     fontSize:'2rem',
                                     color:'#fff',

                                 }}

                    >
                        {overview.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary"
                                sx={{fontFamily:"Nunito",
                                    fontSize:'1.1rem',
                                    color:'#fff'}}>
                        {overview.desc}
                    </Typography>
                    <Link to={"/"}>
                        <Button sx={{background:'rgba(0,0,0,0.5)',color:"#fff",marginTop:5, fontFamiliy:"Nunito"}}>
                            Retour
                        </Button>
                    </Link>
                </CardContent>

            </Card>
        </>
    );
}
