import React from 'react';
import {makeStyles} from "@mui/styles";

import partenaires from "../../static/Partenaire/partenaire";

import ImagePartenaire from "./ImagePartenaire";


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',


        justifyItems:'center',
        alignItems:'center',
        flexDirection: 'row',
        display:'inline-flex',
        flexWrap: 'wrap',



    },

}));
export default function () {
    const classes = useStyles();


    return (
        <div className={classes.root}  id="victories">
            {partenaires.map(overview => (
                <ImagePartenaire overview={overview} />
            ))}


        </div>
    );
}

