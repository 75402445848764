import React from 'react';
import PartenairePrincipal from "../components/PartenairePrincipal/PartenairePrincipal";
import {CssBaseline, IconButton} from "@mui/material";
import Header from "../components/Header";
import {makeStyles} from "@mui/styles";
import PartenaireComponent from "../components/Partenaires/PartenaireComponent";
import Typography from "@mui/material/Typography";
import {ArrowForwardIos} from "@mui/icons-material";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    div: {
        backgroundImage: `url(${process.env.PUBLIC_URL + '/images/amelie.jpg'})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize:'cover',



    },

}));

function Partenaire() {

const classes=useStyles();
    return (
        <div className={classes.div}>
<Header/>
            <h1 >
                <Typography sx={{color:"#070707",
                    fontFamily:"Nunito",
                    fontSize:"2.5rem",
                    fontWeight:'bold',
                    marginTop:25,
                    marginLeft:2,

                }}>Les partenaires qui m'ont soutenu durant ma carrière</Typography>
            </h1>
<PartenairePrincipal/>
<PartenaireComponent />
            <Link to={"../Course"}>
                <IconButton  >
                    <ArrowForwardIos sx={{fontSize:"6rem", left:'50%', alignItems:"center",color: "#fff",
                    }} />
                </IconButton>
            </Link>
        </div>
    );
}

export default Partenaire;
