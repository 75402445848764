import React from 'react';
import Button from "@mui/material/Button";
import {useState} from "react";






const Serial = () => {

    const [Value,setValue]=useState(['']);
    const log=[];

    async function connectSerial() {

        try {
            const port = await navigator.serial.requestPort();
            await port.open({ baudRate: 9600 });

            // eslint-disable-next-line no-undef
            const decoder = new TextDecoderStream();

            port.readable.pipeTo(decoder.writable);

            const inputStream = decoder.readable;
            const reader = inputStream.getReader();

            while (true) {
                const { value, done } = await reader.read();
                if (value) {

                    console.log(value);
                    setValue(value);
                }
                if (done) {
                    console.log('[readLoop] DONE', done);
                    reader.releaseLock();
                    break;
                }
            }

        } catch (error) {
            log.innerHTML = error;
        }
    }



    const test =()=>{
        if (navigator.serial) {
            connectSerial();
        } else {
            alert('Web Serial API not supported.');
        }}
    return (
        <div>
<Button onClick={test}>Click</Button>


            <h1 >Latest TimeStamp: {Value}</h1>
        </div>
    );
};

export default Serial;
