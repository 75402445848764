import React from 'react';
import ImageBiographie from "../components/Biographie/ImageBiographie";
import biographies from "../static/biographie";
import {CssBaseline, IconButton} from "@mui/material";
import Header from "../components/Header";
import {makeStyles} from "@mui/styles";
import Stepper from "../components/standard/Stepper";
import {ArrowForwardIos} from "@mui/icons-material";
import {Link} from "react-router-dom";


const useStyle=makeStyles((theme)=>({
    root:{
        maxWidth: '100%',
        marginTop:20,
        marginLeft:2,
        display:'inline-flex',
        alignItems:'center',
        justifyContent:'center',
        flexWrap: 'wrap'

    },
    div: {
        backgroundImage: `url(${process.env.PUBLIC_URL + '/images/background/melchsee.jpg'})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize:'cover',
    }
}));


function Biographie() {
    const classes = useStyle();

    return (


           <>
<Header />
        <div className={classes.div}>


            {biographies.map(overview => (
                <ImageBiographie  overview={overview} />

            ))}
            <Link to={"../Carriere"}>
                <IconButton  >
                    <ArrowForwardIos sx={{fontSize:"6rem", left:'50%', alignItems:"center",color: "#fff",
                    }} />
                </IconButton>
            </Link>

        </div>


           </>

    );
}

export default Biographie;
