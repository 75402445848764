import * as React from 'react';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {Collapse} from "@mui/material";
import { Link } from "react-router-dom";





export default function ImageOverview({overview,checked}) {



    return (
        <Collapse
            in={checked}
            {...(checked ? { timeout: 1000 } : {})}
        >
        <Card sx={{
            maxWidth: 300 ,
        marginLeft: 5, marginTop:5 }} >

            <CardContent sx={{background:'rgba(0,0,0,0.5)'}}>
                <Typography  gutterBottom variant="h5" component="div"
                sx={{fontFamily:"Nunito",
                fontWeight:'bold',
                fontSize:'2rem',
                    color:'#fff',

                }}

                >
                    {overview.title}
                </Typography>
                <Typography variant="body2" color="text.secondary"
                            sx={{fontFamily:"Nunito",
                        fontSize:'1.1rem',
                        color:'#ddd'}}>
                    {overview.desc}
                </Typography>
                <Link to={"/"+overview.page}>


                <Button sx={{background:'rgba(0,0,0,0.5)',color:"#fff",marginTop:10, fontFamiliy:"Nunito"}} variant="text">{overview.page}</Button>
                    </Link>

            </CardContent>

        </Card>
        </Collapse>
    );
}
