import React from 'react';

const images = [

    {
        url: 'https://www.pocsports.com',
        title: 'Helvetia',
        width: '100%',
        img:'/images/helvetia_small.png'
    },


];

function HelvetiaIcon() {
    return (
        <div>
            {images.map((image) => (
            <img src={image.img} alt="logo" width={75} />
                ))}
        </div>
    );
}

export default HelvetiaIcon;
