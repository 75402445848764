import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import EmailIcon from '@mui/icons-material/Email';
import {Link} from "react-router-dom";
import Header from "../components/Header";
import {makeStyles} from "@mui/styles";

const useStyle=makeStyles((theme)=>({

    div: {
        backgroundImage: `url(${process.env.PUBLIC_URL + '/images/background/thyonbackground.jpg'})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize:'cover',

    }
}));



export default function ContactComponent() {
    const classes=useStyle();

    return (
        <div >
            <div className={classes.div}>
        <Header/>

        <Card sx={{ maxWidth: '100%',
            marginTop:20,
            marginLeft:2,
            display:'inline-flex',
            alignItems:'center',
            justifyContent:'center',
            flexWrap: 'wrap',}}>

                <CardMedia
                    sx={{margin:2}}
                    component="img"
                    width="400"
                    image="../images/portrait.jpg"
                    alt="portrait"
                />
                <CardContent sx={{background:'rgba(0,0,0,0.5)', margin:1}}>
                    <Typography gutterBottom variant="h5" component="div"
                                sx={{fontFamily:"Nunito",
                                        fontWeight:'bold',
                                        fontSize:'2rem',
                                        color:'#fff',marginBottom:1}}>
                        Contact
                    </Typography>

                    <List
                        sx={{
                            width:'clamp 124px,(304px-100%)*999,100%)',
                            bgcolor: 'background.paper',

                        }}
                    >

                        <ListItem>
                            <ListItemAvatar>
                                <Avatar >
                                    <EmailIcon
                                        onClick={() => {

                                        window.open("mailto:ameliereymond.fansclub@gmail.com")
                                    }}/>
                                </Avatar>
                            </ListItemAvatar>

                            <ListItemText sx={{fontFamily:"Nunito",

                                fontSize:'1.1rem',
                                color:'black',
                            width:'100%',
                            }}
                                          primary="ameliereymond.fansclub@gmail.com"
                                          secondary=""
                           onClick={() => {

                                window.open("mailto:ameliereymond.fansclub@gmail.com")
                            }}
                            />

                        </ListItem>


                    </List>

                    <Typography variant="body2" color="text.secondary" sx={{fontFamily:"Nunito",

                        fontSize:'1.1rem',
                        color:'black'}}>

                    </Typography>
                    <Link to={"/"}>
                    <Button sx={{background:'rgba(0,0,0,0.5)',color:"#fff",marginTop:5, fontFamiliy:"Nunito"}}>
                        Retour
                    </Button>
                    </Link>
                </CardContent>


        </Card>

            </div>
        </div>
    );
}
