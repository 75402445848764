
const biographies = [
    {
        title: 'Biographie',
        desc:

            'Née à Bâle le 18 juin 1987, j\'ai grandi à Sion, ville au pieds des montagnes valaisannes en Suisse.\n' +
            '\n' +
            'J\'ai commencé à skier dès mon plus jeune âge. Partis de simples activités familiales, les sports de neige ont pris une importance grandissante au fil des années. Je m\'initie au ski de compétition dès l\'âge de 13 ans puis découvre le télémark à 17 ans grâce à Jean-Jacques Dayer.\n' +
            '\n' +
            'Grâce aux bons conseils de Françoise Besse Matter, championne du monde de télémark, je progresse très vite dans cette discipline.\n' +
            '\n' +
            'Depuis 2007, je vis ma passion sur les coupes du monde à travers l\'Europe et l\'Amérique.\n' +
            '\n' +
            'En parallèle j\'ai toujours continué la gymnastique aux agrès, une discipline alliant force souplesse et rigueur. Un titre de vice-championne suisse à la barre fixe en 2013 ont été mon plus grand succès\n' +
            '\n' +
            'Si le sport a une place prédominante dans ma vie, j\'ai choisi de faire des études à l\'ETHZ qui m\'ont apporté un Master en Sciences du mouvements, spécialisation en biomécanique, en 2013.\n' +
            '\n' +
            'En 2018, j\'ai fait une saison sabbatique pour donner naissance à notre première fille. J\'ai repris la compétition lors de la saison 2018/19 avec succès, couronnée par 4 globes en coupe du monde et 2 titres de championne du monde à Rjukan en Norvège. \n' +

                '\n' + 'Le Covid-19 s\'est ensuite invité au monde durant les saisons 2019/2020 et 2020/21. Malgré des saisons raccourcies et toujours remplies d\'incertitude liées à cette pandémie, j\'ai à nouveau remporté divers globes et 4 titres de championne du monde à Melchseefrutt en mars 2021. Fin 2021 notre 2ème fille vient au monde et je fais une nouvelle pause de compétition.\n' +
            'Passionnée par mon sport et mes entraînements, et avide des émotions des compétitions j\'ai décidé de repartir pour une saison de compétition qui espérons-le sera normale.',



        url: process.env.PUBLIC_URL + '/images/amelie.jpg',
        time: 1500,
    },




];

export default biographies;
