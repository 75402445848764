import React from 'react';
import {makeStyles} from "@mui/styles";
import ImageCourse from "./ImageCourse";

import courses from "../../static/Courses/courses";
import StaticMap from "../Map/StaticMap";
import {ArrowForwardIos} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import {Link} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',

        justifyItems:'center',
        alignItems:'center',
        flexDirection: 'row',
        display:'inline-flex',
        flexWrap: 'wrap',



    },

}));
export default function () {
    const classes = useStyles();

    return (
        <div className={classes.root}  >

                {
                    courses.map(overview => (
                        <ImageCourse overview={overview}/>
                    ))
                }


            <Link to={"../Contact"}>
            <IconButton  >
                <ArrowForwardIos sx={{fontSize:"6rem", left:'50%', alignItems:"center",color: "#fff",
                }} />
            </IconButton>
                </Link>
        </div>
    );
}

