
const courses = [
    {
        title: 'World Cup',
        desc: '',
        date:'08-01-2023',
        place:'Les Contamines',
        nation:'FRA',
        link:'https://www.lescontamines.com/hiver',
        url: process.env.PUBLIC_URL + '/images/course/France.png',
        time: 1500,
        id:1,
    },

    {
        title: 'World Cup',
        desc: '',
        date:'09-01-2023',
        place:'Les Contamines',
        nation:'FRA',
        link:'https://www.lescontamines.com/hiver',
        url: process.env.PUBLIC_URL + '/images/course/France.png',
        time: 1500,

    },
    {
        title: 'World Cup',
        desc: '',
        date:'20-01-2023',
        place:'Samoens',
        nation:'FRA',
        link:'https://www.samoens.com',
        url: process.env.PUBLIC_URL + '/images/course/France.png',
        time: 1500,

    },

    {
        title: 'World Cup',
        desc: '',
        date:'21-01-2023',
        place:'Samoens',
        nation:'FRA',
        link:'https://www.samoens.com',
        url: process.env.PUBLIC_URL + '/images/course/France.png',
        time: 1500,

    },

    {
        title: 'World Cup',
        desc: '',
        date:'27-01-2023',
        place:'Melchsee-Frutt',
        nation:'SUI',
        link:'',
        url: process.env.PUBLIC_URL + '/images/course/Suisse.png',
        time: 1500,
        id:2,
    },
    {
        title: 'World Cup',
        desc: '',
        date:'28-01-2023',
        place:'Melchsee-Frutt',
        nation:'SUI',
        link:'',
        url: process.env.PUBLIC_URL + '/images/course/Suisse.png',
        time: 1500,
    },
    {
        title: 'World Cup',
        desc: '',
        date:'29-01-2023',
        place:'Melchsee-Frutt',
        nation:'SUI',
        link:'',
        url: process.env.PUBLIC_URL + '/images/course/Suisse.png',
        time: 1500,
    },

    {
        title: 'World Cup',
        desc: '',
        date:'16-02-2023',
        place:'Aal',
        nation:'NOR',
        link:'',
        url: process.env.PUBLIC_URL + '/images/course/Norvege.png',
        time: 1500,
    },
    {
        title: 'World Cup',
        desc: '',
        date:'17-02-2023',
        place:'Aal',
        nation:'NOR',
        link:'',
        url: process.env.PUBLIC_URL + '/images/course/Norvege.png',
        time: 1500,
    },
    {
        title: 'World Cup',
        desc: '',
        date:'18-02-2023',
        place:'Aal',
        nation:'NOR',
        link:'',
        url: process.env.PUBLIC_URL + '/images/course/Norvege.png',
        time: 1500,
    },
    {
        title: 'World Cup',
        desc: '',
        date:'09-03-2023',
        place:'Krvavec',
        nation:'SLO',
        link:'',
        url: process.env.PUBLIC_URL + '/images/course/Norvege.png',
        time: 1500,
    },
    {
        title: 'World Cup',
        desc: '',
        date:'10-03-2023',
        place:'Krvavec',
        nation:'SLO',
        link:'',
        url: process.env.PUBLIC_URL + '/images/course/Norvege.png',
        time: 1500,
    },
    {
        title: 'World Cup',
        desc: '',
        date:'11-03-2023',
        place:'Krvavec',
        nation:'SLO',
        link:'',
        url: process.env.PUBLIC_URL + '/images/course/Norvege.png',
        time: 1500,
    },
    {
        title: 'World Cup',
        desc: '',
        date:'16-03-2023',
        place:'Oberjoch',
        nation:'GER',
        link:'',
        url: process.env.PUBLIC_URL + '/images/course/Norvege.png',
        time: 1500,
    },
    {
        title: 'World Cup',
        desc: '',
        date:'17-03-2023',
        place:'Oberjoch',
        nation:'GER',
        link:'',
        url: process.env.PUBLIC_URL + '/images/course/Norvege.png',
        time: 1500,
    },
    {
        title: 'World Cup',
        desc: '',
        date:'18-03-2023',
        place:'Oberjoch',
        nation:'GER',
        link:'',
        url: process.env.PUBLIC_URL + '/images/course/Norvege.png',
        time: 1500,
    },

    {
        title: 'World Champs',
        desc: '',
        date:'22-03-2023',
        place:'Mürren',
        nation:'SUI',
        link:'',
        url: process.env.PUBLIC_URL + '/images/course/Suisse.png',
        time: 1500,
        id:3,
    },
    {
        title: 'World Champs',
        desc: '',
        date:'23-03-2023',
        place:'Mürren',
        nation:'SUI',
        link:'',
        url: process.env.PUBLIC_URL + '/images/course/Suisse.png',
        time: 1500,
        id:3,
    },
    {
        title: 'World Champs',
        desc: '',
        date:'25-03-2023',
        place:'Mürren',
        nation:'SUI',
        link:'',
        url: process.env.PUBLIC_URL + '/images/course/Suisse.png',
        time: 1500,
        id:3,
    },
    {
        title: 'World Champs',
        desc: '',
        date:'26-03-2023',
        place:'Mürren',
        nation:'SUI',
        link:'',
        url: process.env.PUBLIC_URL + '/images/course/Suisse.png',
        time: 1500,
        id:3,
    },

    {
        title: 'Swiss Champs',
        desc: '',
        date:'31-03-2023',
        place:'Thyon',
        nation:'SUI',
        link:'',
        url: process.env.PUBLIC_URL + '/images/course/Suisse.png',
        time: 1500,
    },
    {
        title: 'Swiss Champs',
        desc: '',
        date:'01-04-2023',
        place:'Thyon',
        nation:'SUI',
        link:'',
        url: process.env.PUBLIC_URL + '/images/course/Suisse.png',
        time: 1500,
    },




];

export default courses;
