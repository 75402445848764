import * as React from 'react';
import {makeStyles} from "@mui/styles";
import ContactComponent from "../components/ContactComponent";
import {ArrowForwardIos} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import {Link} from "react-router-dom";
const useStyle=makeStyles((theme)=>({

    div: {
        backgroundImage: `url(${process.env.PUBLIC_URL + '/images/background/thyonbackground.jpg'})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize:'cover',

    }
}));



export default function Contact() {
    const classes=useStyle();

    return (
        <div className={classes.div} >

<ContactComponent/>
            <Link to={"../Home"}>
            <IconButton  >
                <ArrowForwardIos sx={{fontSize:"6rem", left:'50%', alignItems:"center",color: "#fff",
                }} />
            </IconButton>
            </Link>

        </div>
    );
}
