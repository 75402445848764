
const overviews = [
    {
        title: 'Biographie',
        page:'biographie',
        desc:
            "Si vous voulez connaître plus en détail ma biograpie ",
        url: process.env.PUBLIC_URL + '/images/globe.jpg',
        time: 1500,
    },
    {
        title: 'Partenaires',
        page:'partenaires',
        desc:
            'Merci aux partenaires qui me soutiennent',
        url: process.env.PUBLIC_URL + '/images/championnatmonde.jpg',
        time: 1500,
    },
    {
        title: 'Carrière',
        page: 'carriere',
        desc: "Découvrez mon parcours d'athlète",
        url: process.env.PUBLIC_URL + '/images/victoire.jpg',
        time: 1500,
    },

    {
        title: 'Contact',
        page:'contact',
        desc:
            'Pour me contacter. Vous trouvrez les informations ici',
        url: process.env.PUBLIC_URL + '/images/helvetia.png',
        time: 1500,
    },
    {
        title: 'Courses',
        page:'course',
        desc:
            'Le calendrier des courses de la saison prochaine',
        url: process.env.PUBLIC_URL + '/images/helvetia.png',
        time: 1500,
    },




];

export default overviews;
