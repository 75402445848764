
const itemData = [
    {
        img: '../images/globe.jpg',
        label: "WC Thyon 2021",
        title: '',
        author: 'copyright 360dsm',
        rows: 2,

    },

    {
        img: '../images/victoire.jpg',
        title: 'copyright 360dsm',
        label:"WC Thyon 2021",
        row:1,
        cols:2,
    },

    {
        img: '../images/action.jpg',
        label:'FIS Thyon 2021',
        title: 'copyright 360dsm',
        rows: 2,

    },

    {
        img: '../images/goldmedal.jpg',
        title: 'copyright Samuel Decout',
        label: "WCS 2021 Melchsee-Frutt",
        cols: 2,
    },

    {
        img: '../images/background/course.jpg',
        label: "WC Thyon 2021",
        title: 'copyright 360dsm',
    },
    {
        img: './images/background/Montblanc.jpg',
        label: "WC Passy 2021",
        title: 'copyright Samuel Decout',
    },
    {
        img: '../images/amelie.jpg',
        title: 'copyright 360dsm',
        label: "WC Thyon 2021",
        cols: 2,


    },]

export default itemData;

