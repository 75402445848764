
const partenaireprincipals = [
    {
        title: 'Partenaire Principal',

        desc:
            "Helvetia Assurances me soutiennent depuis 2011 comme partenaire principal. Helvetia est une assurance suisse leader de son secteur, Helvetia conseille sa clientèle pour toutes les questions relatives à l'assurance et à la prévoyance. En collaboration avec MoneyPark, elle forme par ailleurs une équipe solide autour du logement en propriété. 1,3 million de clientes et de clients. 3'600 collaboratrices et collaborateurs. Helvetia rend le monde plus sûr.  ",
        url: process.env.PUBLIC_URL + '/images/helvetia.png',
        time: 1500,
        link:"https://www.helvetia.ch"
    },





];

export default partenaireprincipals;
