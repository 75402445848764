

const victories = [
    {
        title: 'Globes',
        desc:
            "47 globes dont 11 généraux ",
        url: process.env.PUBLIC_URL + '/images/globe.jpg',
        time: 1500,
    },
    {
        title: 'Championnats du monde',
        desc:
            '17 titres individuels et 4 titres en équipe',
        url: process.env.PUBLIC_URL + '/images/championnatmonde.jpg',
        time: 1500,
    },
    {
        title: 'Coupe du monde',
        desc:
            '164 victoires en Coupe du monde',
        url: process.env.PUBLIC_URL + '/images/victoire.jpg',
        time: 1500,
    },

    {
        title: 'Premier podium',
        desc:
            '21 janvier 2007: Trysil en Norvège',
        url: process.env.PUBLIC_URL + '/images/carriere/trysil.jpg',
        time: 1500,
    },

    {
        title: 'Première Victoire ',
        desc:
            '18 mars 2007: Oberjoch en Allemagne',
        url: process.env.PUBLIC_URL + '/images/carriere/oberjoch.JPG',
        time: 1500,
    },

    {
        title: 'Grand Chelem ',
        desc:
            "En 2017 un 100%",
        url: process.env.PUBLIC_URL + '/images/carriere/thyon.jpg',
        time: 1500,
    },
    {
        title: 'Une fin émotionnelle ',
        desc:
            "11 mars 2023: Mürren en Suisse",
        url: process.env.PUBLIC_URL + '/images/course/teamEvent.jpg',
        time: 1500,
    },




];

export default victories;
