
const partenaires = [
    {
        title: 'Partenaire',

        desc:
            "Rossignol m'équipe avec des skis depuis mes débuts en Coupe du monde",
        url: process.env.PUBLIC_URL + '/images/partenaire/rossignol.png',
        link:"https://www.rossignol.com",
        time: 1500,
    },
    {
        title: 'Partenaire',

        desc:
            "KV+ me soutient avec du matériel de ski de fond",
        url: process.env.PUBLIC_URL + '/images/partenaire/kvplus.jpg',
        link:"https://www.kvplus.ch",
        time: 1500,
    },
    {
        title: 'Partenaire',

        desc:
            "POC protège ma tête et optimise ma vue ",
        url: process.env.PUBLIC_URL + '/images/partenaire/Poc.png',
        link:"https://www.pocsports.com",
        time: 1500,
    },
    {
        title: 'Partenaire',

        desc:
            "Scarpa est ma référence pour les chaussures de telemark",
        url: process.env.PUBLIC_URL + '/images/partenaire/scarpa.png',
        link:"https://newrocksport.ch/",
        time: 1500,

    },
    {
        title: 'Partenaire',

        desc:
            "Rottefella est ma fixation de telemark pour la compétition ",
        url: process.env.PUBLIC_URL + '/images/partenaire/rottefella.png',
        link:"https://www.rottefella.com",
        time: 1500,

    },
    {
        title: 'Partenaire',

        desc:
            "Level me donne chaud aux mains et de la précision ",
        url: process.env.PUBLIC_URL + '/images/partenaire/level.png',
        link:"https://www.levelgloves.com",
        time: 1500,

    },
    {
        title: 'Partenaire',

        desc:
            "Theytaz Sports aux Collons est mon magasin de sport",
        url: process.env.PUBLIC_URL + '/images/partenaire/theytaz.png',
        link:"https://www.theytazsports.ch",
        time: 1500,
    },
    {
        title: 'Partenaire',

        desc:
            "Le domaine skiable de Thyon dispose de pistes d'entraînement optimales",
        url: process.env.PUBLIC_URL + '/images/partenaire/thyon.png',
        link:"https://www.thyon.ch",
        time: 1500,

    },
    {
        title: 'Partenaire Swiss-ski',

        desc:
            "Sunrise est le partenaire principal de Swiss-ski.",
        url: process.env.PUBLIC_URL + '/images/partenaire/sunrise.png',
        link:"https://www.sunrise.ch",
        time: 1500,

    },
    {
        title: 'Partenaire Swiss-ski',

        desc:
            "Raiffeisen est un partenaire premium de Swiss-ski.",
        url: process.env.PUBLIC_URL + '/images/partenaire/raiffeisen.png',
        link:"https://www.raiffeisen.ch",
        time: 1500,

    },
    {
        title: 'Partenaire Swiss-ski',

        desc:
            "Helvetia est un partenaire premium de Swiss-ski",
        url: process.env.PUBLIC_URL + '/images/partenaire/helvetia_swissski.png',
        link:"https://www.helvetia.ch",
        time: 1500,

    },
    {
        title: 'Partenaire Swiss-ski',

        desc:
            "BKW est un partenaire premium de Swiss-ski",
        url: process.env.PUBLIC_URL + '/images/partenaire/bkw.png',
        link:"https://www.bkw.ch",
        time: 1500,

    },








];

export default partenaires;
