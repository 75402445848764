import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import MailIcon from '@mui/icons-material/Mail';
import {Copyright} from "@mui/icons-material";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import ButtonBases from "./ButtonBases";
import PocIcon from "../Icons/PocIcon";
import HelvetiaIcon from "../Icons/HelvetiaIcon";
import RossignolIcon from "../Icons/RossignolIcon";
import KVplusIcon from "../Icons/KVplusIcon";

export default function BottomMenu() {
    const [value, setValue] = React.useState('recents');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <BottomNavigation
            sx={{ left:'50%', alignItems:'center',justifyContent:'center', }}

                          value={value} onChange={handleChange}>
            <BottomNavigationAction
                label="Instagram"
                value="Instagram"
                onClick={() => {

                    window.open("https://www.instagram.com/ameliereymond.telemark/")
                }}
                icon={<InstagramIcon/>}
            />

            <BottomNavigationAction
                label="Facebook"
                value="Facebook"
                onClick={() => {

                    window.open("https://fr-fr.facebook.com/ameliereymond.telemark")
                }}
                icon={<FacebookIcon />}
            />
            <BottomNavigationAction
                label="Contact"
                value="Contact"
                onClick={() => {

                    window.open("mailto:ameliereymond.fansclub@gmail.com")
                }}
                icon={<MailIcon />}
            />
            <BottomNavigationAction
                label="Helvetia"
                value="Helvetia"
                onClick={() => {

                    window.open("https://www.helvetia.ch")
                }}
                icon={<HelvetiaIcon />}
            />
            <BottomNavigationAction
                label="Impressum"
                value="Impressum"
                onClick={() => {

                    window.open("https://www.ameliereymond.ch/impressum")
                }}
                icon={<Copyright/>}
            />

            </BottomNavigation>

    );
}
