import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {Collapse} from "@mui/material";
import Button from "@mui/material/Button";




export default function ImagePartenaire({overview}) {


    return (

            <Card sx={{
                width:'clamp 124px,(304px-100%)*999,100%)',
                marginLeft:2, marginTop:5 }} >
                <CardMedia

                    component="img"
                    minHeight="100"
                    image={overview.url}
                    alt="image"
                />
                <CardContent sx={{background:'rgba(0,0,0,0.5)'}}>
                    <Typography  gutterBottom variant="h5" component="div"
                                 sx={{fontFamily:"Nunito",
                                     fontWeight:'bold',
                                     fontSize:'1.2rem',
                                     color:'#fff',

                                 }}

                    >
                        {overview.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary"
                                sx={{fontFamily:"Nunito",
                                    fontSize:'1rem',
                                    color:'#ddd'}}>
                        {overview.desc}
                    </Typography>

                        <Button onClick={() => {

                            window.open(overview.link)
                        }}
                                sx={{background:'rgba(0,0,0,0.5)',color:"#fff",marginTop:5,alignItem:"center", fontFamiliy:"Nunito"}} variant="text">Plus d'informations</Button>

                </CardContent>

            </Card>

    );
}
