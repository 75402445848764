import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Typography from '@mui/material/Typography';




export default function ImageCard({success}) {


    return (

        <Card sx={{
            maxWidth: 645 ,
        marginLeft:2, marginTop:10}} >
            <CardMedia

                component="img"
                height="440"
                image={success.url}
                alt="image"
            />
            <CardContent sx={{background:'rgba(0,0,0,0.5)'}}>
                <Typography  gutterBottom variant="h5" component="div"
                sx={{fontFamily:"Nunito",
                fontWeight:'bold',
                fontSize:'2rem',
                    color:'#fff',

                }}

                >
                    {success.title}
                </Typography>
                <Typography variant="body2" color="text.secondary"
                            sx={{fontFamily:"Nunito",
                        fontSize:'1.5rem',
                                fontWeight:'bold',
                        color:'#ddd'}}>
                    {success.desc}
                </Typography>

            </CardContent>

        </Card>

    );
}
